<template>
  <section style="text-transform: uppercase;">
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <div v-if="loading" class="col-md-12" style="text-align: center;padding-top: 255px;">
      <pm-ProgressSpinner />
    </div>                                                             <!--Se for do tipo fundamental dois ou se foi definido que no periodo tera mais de um professor exiba o seletor de disciplinas-->
    <pm-Card  v-if="loading == false && selecionarDisciplina == true && (segmentInfoTipo == 2 || periodoInfo.possui_mais_de_um_professor)">
      <template #content>
        <div class="p-fluid formgrid grid">
          <div class=" field col-12 md:col-6">
            <label for="disciplina">Selecionar disciplina</label>
            <pm-Dropdown  v-model="disciplinaSelecionada"  optionLabel="nome" :options="disciplinaTurma" @change="buscarProfessoresPossiveis"   placeholder="Selecione a disciplina" />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="col-md-5" >
          <pm-Button  type="button" @click="this.$router.push({name: 'coordenacao-turma-hab', params: {pg: this.pg} });"
            icon="pi pi-angle-left"
            label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
          </pm-Button>

          <pm-Button  type="button" @click="irOutraPg(2)"
            icon="pi pi-id-card"
            label="ALUNOS" title="ALUNOS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
          </pm-Button>

          <pm-Button  type="button" @click="irOutraPg(0)"
            icon="pi pi-id-card"
            label="DISCIPLINAS" title="DISCIPLINAS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
          </pm-Button>
        </div>
      </template>
    </pm-Card>
    <pm-Card v-if="loading == false && selecionarDisciplina == false">
      <template #title> <!--Se for do tipo fundamental dois ou se foi definido que no periodo tera mais de um professor -->
        <div v-if="segmentInfoTipo == 2 || periodoInfo.possui_mais_de_um_professor">
          Adicionar professor para disciplina de <u>{{disciplinaSelecionada.nome}}</u>   <span class="cabecario-turma" v-if="turma_ativa" style="float:right"> Turma Habilitada </span>
        </div>
        <div v-if="segmentInfoTipo == 1 && !periodoInfo.possui_mais_de_um_professor">
          Adicionar professor para <u>Todas As Disciplinas</u> <span class="cabecario-turma" v-if="turma_ativa" style="float:right"> Turma Habilitada </span>
        </div>
        <div v-if="segmentInfoTipo == 0">
          Adicionar professor para <u>Educação Infantil</u> <span class="cabecario-turma" v-if="turma_ativa" style="float:right"> Turma Habilitada </span>
        </div>
      </template>
      <template #content>
        <div :class="{ 'pm-card': true, 'pm-card-disabled': isSituacaoAnoZero }">
          <pm-PickList v-model="listaDeProfessores" dataKey="id"
            @move-to-target="moveToTarget" @move-to-source="moveToSource"
            id="botaoSelecionarTudoDisabled">
            <template #sourceheader>
              Professores disponíveis
            </template>
            <template #targetheader>
              Professor selecionado para lecionar a(s) disciplina(s)
            </template>
            <template #item="slotProps">
              <div class="p-caritem">
                {{ slotProps.item.nome }} {{ slotProps.item.sobrenome }}
                <span style="margin-left:10px;" class="badge bg-danger table_style" :title="slotProps.item.qual_deficiencia" v-if="slotProps.item.deficiencia == 1">NEE</span>
              </div>
            </template>
          </pm-PickList>
        </div>
      </template>
      <template #footer>
        <pm-Button v-if="segmentInfoTipo == 2 ||  periodoInfo.possui_mais_de_um_professor" type="button" icon="pi pi-angle-left"
          label="Escolher outra disciplina"   @click="escolherOutraDisciplina" class="p-button-sm p-button-warning btn-color"
        >
        </pm-Button>
          &nbsp;
        <pm-Button  type="button" @click="this.$router.push({name: 'coordenacao-turma-hab'});" icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color"
        >
        </pm-Button>
      </template>
    </pm-Card>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Turma } from "@/class/turma.js";
import { Calendario } from "@/class/calendario.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Servidores } from "@/class/servidores";

import axios from 'axios'


export default defineComponent({
  props: {
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
    turno:{},
    pg:{}
  },
  name: 'turma-config',
  data () {
    return {
      disciplinaSelecionada:false,
      selecionarDisciplina : false,
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Turmas'},
      ],
      loading:false,
      listaDeProfessores:[],
      professorDaDisciplinaAtual:'',
      disciplinaTurma:[],
      st_etapa_id:{},
      st_periodo_id:{},
      st_turma_id:{},
      st_escola_id:{},
      turma_dados:[],
      turma_ativa:0,
      segmentInfoTipo:-1,
      theAno:'',
      periodoInfo:'',
      segmentoTipo:-1,
      situacaoAno:0
    }
  },
  computed: {
    isSituacaoAnoZero() {
      return this.situacaoAno == 0;
    }
  },
  methods:{
    irOutraPg(pg){
      if(pg == 0){
       this.$router.push({name: 'turma-add-disciplina-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 1){
       this.$router.push({name: 'turma-add-professor-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 2){
       this.$router.push({name: 'turma-add-aluno-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
    },
    async verificarDisciplinaEducacoInfantil() {
      const data3 = await Turma.turmasDisciplina(this.st_turma_id);
      if (data3.data.length == 0) {
        const arr = {
          turma_id: parseInt(this.st_turma_id),
          disciplina_id: 2,
        };
        const dt = await Turma.addDisciplina(arr);
      }
    },
    escolherOutraDisciplina() {
      this.disciplinaSelecionada = '';
      this.selecionarDisciplina = !this.selecionarDisciplina;
    },
    async buscarProfessoresPossiveis() {
      const data = await Turma.verificaNaDisciplinaProfessor(this.disciplinaSelecionada.id, this.st_turma_id);
      const novo = {
        escola_id: this.st_escola_id,
        segmento_id: this.st_etapa_id,
        turno: this.items_bread[4].label,
        disciplina_id: this.disciplinaSelecionada.id,
        ano: this.theAno
      }
      const data1 = await Servidores.disciplinasRelacionadas(novo);

      this.listaDeProfessores = [];
      this.professorDaDisciplinaAtual = '';
      if (data.data != "Professor não Encontrado!") {
        this.professorDaDisciplinaAtual = data.data.professor;
        const arrayOfValues = [];
        for (const el of data1.data) {
          if (el.professor != null) {
            if (this.professorDaDisciplinaAtual.id == el.professor.id)
              {continue;}
            arrayOfValues.push(el.professor);
          }
        }
        this.listaDeProfessores.push(arrayOfValues);
        this.listaDeProfessores.push([this.professorDaDisciplinaAtual]);
      }
      else {
        const arrayOfValues = [];
        for (const el of data1.data) {
          if (el.professor != null) {
            arrayOfValues.push(el.professor);
          }
        }
        this.listaDeProfessores.push(arrayOfValues);
        this.listaDeProfessores.push([]);
      }
      this.selecionarDisciplina = false;

    },
    moveToTarget(event){
      if (this.listaDeProfessores[1].length > 1 && this.segmentoTipo != 0) {
        this.listaDeProfessores[0].push(this.listaDeProfessores[1][1]);
        this.listaDeProfessores[1].splice(-1,1);
        this.$vaToast.init({
            message: "Um professor já está selecionado!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'warning',
            fullWidth: false,
        });
        return false;
      }
      else if (this.listaDeProfessores[1].length > 2 && this.segmentoTipo == 0) {
        this.listaDeProfessores[0].push(this.listaDeProfessores[1][2]);
        this.listaDeProfessores[1].splice(-1,1);
        this.$vaToast.init({
            message: "Dois professores já estão selecionados!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'warning',
            fullWidth: false,
        });
        return false;
      }
      if (this.segmentInfoTipo == 0)
        {this.addDisciplinaEnsinoInfantil(event.items[0].id);}
      //fundamental 1, ern e eja
      else if (this.segmentInfoTipo == 1 && !this.periodoInfo.possui_mais_de_um_professor)
        {this.addFundIDisciplina(event.items[0].id);}
      else //fundamental 2 e eja
        {this.addDisciplinaNaoFundamentalI(event.items[0].id);}
    },
    moveToSource(event){
      if (this.segmentInfoTipo == 0)
        {this.deletarDisciplinaEnsinoInfantil(event.items[0].id);}
      //fundamental 1, ern e eja
      else if (this.segmentInfoTipo == 1 && !this.periodoInfo.possui_mais_de_um_professor)
        {this.deletarFundIDisciplina(event.items[0].id);}
      else //fundamental 2 e eja
        {this.deletarDisciplinaNaoFundamentalI(event.items[0].id);}
    },

   async addDisciplinaEnsinoInfantil(professor_id) {
      try {
          this.loading = true;
          const data3 = await Turma.turmasDisciplina(this.st_turma_id);
          const novo = {
            turma_id: this.st_turma_id,
            professor_id: professor_id,
            turma_disciplina_id: data3.data[0].id
          }
          const data = await Turma.addProfessorTurma(novo);
          await this.buscaTurmaInfo();
          this.loading = false;
          this.selecionarDisciplina = false;
        }
        catch (e) {

        }
    },

    async addDisciplinaNaoFundamentalI(professor_id) {
      try {
          this.loading = true;
          const novo = {
            turma_id: this.st_turma_id,
            professor_id: professor_id,
            turma_disciplina_id: this.disciplinaSelecionada.turma_disciplina_id,
          }
          const data = await Turma.addProfessorTurma(novo);
          await this.buscaTurmaInfo();
          this.loading = false;
          this.selecionarDisciplina = false;
        }
        catch (e) {

        }
    },
    async addFundIDisciplina(professor_id){
      try {
        this.loading = true;
        for (const el of this.disciplinaTurma) {
          const novo = {
              turma_id: parseInt(this.st_turma_id),
              professor_id: professor_id,
              turma_disciplina_id: el.turma_disciplina_id,
          }
          const data = await Turma.addProfessorTurma(novo);
        }
        this.buscaTurmaInfo();
        this.loading = false;
      }
      catch(e) {

      }
    },
    async deletarDisciplinaEnsinoInfantil(professor_id) {
      try {
          this.loading = true;
          const data3 = await Turma.turmasDisciplina(this.st_turma_id);
          const novo = {
            turma_id: this.st_turma_id,
            professor_id: professor_id,
            turma_disciplina_id: data3.data[0].id
          }
          const data = await Turma.deletarProfessorDaDisciplina(novo);
          await this.buscaTurmaInfo();
          this.loading = false;
          this.selecionarDisciplina = false;
      }
      catch(e) {
      }
    },
    async deletarDisciplinaNaoFundamentalI(professor_id) {
      try {

          this.loading = true;
          const novo = {
            turma_id: this.st_turma_id,
            professor_id: professor_id,
            turma_disciplina_id:  this.disciplinaSelecionada.turma_disciplina_id,
          }
          const data = await Turma.deletarProfessorDaDisciplina(novo);
          await this.buscaTurmaInfo();
          this.loading = false;
          this.selecionarDisciplina = false;
      }
      catch(e) {
      }
    },
    async deletarFundIDisciplina(professor_id){
        try {
          this.loading = true;
          for (const el of this.disciplinaTurma) {
            const novo = {
              turma_id: parseInt(this.st_turma_id),
              professor_id: professor_id,
              turma_disciplina_id: el.turma_disciplina_id,
            }
            const data = await Turma.deletarProfessorDaDisciplina(novo);
            this.buscaTurmaInfo();
          }
          this.loading = false;
        }
        catch(e) {

        }
    },
    async buscaTurmaInfo() {
      const turma = {
          turma_id: this.st_turma_id,
          etapa_id: this.st_etapa_id,
          escola_id: this.st_escola_id,
      }

      const data = await Turma.aturmaConfi(turma);
      this.disciplinaTurma = [];

      if (data.data == 'A Turma Não possui Disciplina!') {
        this.loading = false;
        if (window.confirm("É necessário adicionar as disciplinas na turma antes de adicionar o(a) professor(a)!")) {
          this.$router.push({name: 'turmas'});
        }
      }
      //buscar as disciplinas da turma
      for (const el of data.data) {
        el.disciplina.disciplina_id = el.disciplina_id;
        if (el.jatemprofessor != "Professor não Encontrado!") {
          el.disciplina.ProfessorDaDisciplina = el.jatemprofessor;
        }
        el.disciplina.turma_disciplina_id = el.id;
        this.disciplinaTurma.push(el.disciplina);
      }
      //apenas para fundamental I, ERN, ou Infatil
      if (this.segmentInfoTipo == 0 || this.segmentInfoTipo == 1 && !this.periodoInfo.possui_mais_de_um_professor) {
        this.professorDaDisciplinaAtual = this.disciplinaTurma[0].ProfessorDaDisciplina;
        //buscar professores relacionados a disciplinas
        const professoresRelacionados = data.data[0].professores;
        if (professoresRelacionados.length) {
          const DisciplinafundamentalUmOuInfantil = (this.segmentInfoTipo == 1 ? 1 : 2);
          const novo = {
            escola_id: this.st_escola_id,
            segmento_id: this.st_etapa_id,
            turno: this.items_bread[4].label,
            disciplina_id: DisciplinafundamentalUmOuInfantil,
            ano: this.theAno
          }
          const data1 = await Servidores.disciplinasRelacionadas(novo);
          this.listaDeProfessores = [];
          //Verificar se a turma ja tem o professor
          if (this.professorDaDisciplinaAtual == null) {
            const arrayOfValues = [];
            for (const el of data1.data) {
              if (el.professor != null) {
                arrayOfValues.push(el.professor);
              }
            }
            this.listaDeProfessores.push(arrayOfValues);
            this.listaDeProfessores.push([]);
          }
          else {
            const arrayOfValues = [];
            for (const el of data1.data) {
              if (el.professor != null) {
                console.log(this.professorDaDisciplinaAtual);


                if (this.segmentoTipo == 0) {
                  const found = this.professorDaDisciplinaAtual.find(obj => obj.id == el.professor.id);
                  if (found == undefined)
                    {arrayOfValues.push(el.professor);}
                }
                else {
                  if (this.professorDaDisciplinaAtual.id != el.professor.id)
                    {arrayOfValues.push(el.professor);}
                }
              }
            }
            this.listaDeProfessores.push(arrayOfValues);
            if (this.segmentoTipo == 0)
              {this.listaDeProfessores.push(this.professorDaDisciplinaAtual);}
            else
              {this.listaDeProfessores.push([this.professorDaDisciplinaAtual]);}
          }
        }
        this.selecionarDisciplina = false;
      }
      else {
        this.selecionarDisciplina = true;
      }
    },
  },


  async created(){
      if(this.etapa_id == null ||  this.periodo_id == null || this.turma_id == null  ||  this.escola_id == null){
        this.st_etapa_id = sessionStorage.getItem("TurmaConfg_etapa_id");
        this.st_periodo_id = sessionStorage.getItem("TurmaConfg_periodo_id");
        this.st_turma_id = sessionStorage.getItem("TurmaConfg_turma_id");
        this.st_escola_id = sessionStorage.getItem("TurmaConfg_escola_id");
        this.st_turno = sessionStorage.getItem("TurmaConfg_turno");
        this.erroturmaeditar = sessionStorage.getItem("erroturmaeditar");
        //this.$router.push({name: 'escolas'});
      }else{
        this.st_etapa_id = this.etapa_id;
        this.st_periodo_id = this.periodo_id;
        this.st_turma_id = this.turma_id;
        this.st_escola_id =this.escola_id;
        this.st_turno =this.turno;
      }

      const turma = {
        turma_id: this.st_turma_id,
        etapa_id: this.st_etapa_id,
        periodo_id: this.st_periodo_id,
        escola_id: this.st_escola_id,
      }

      const resp_turma = await Turma.aturma(turma);
      const  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
      ];
      this.items_bread = tt;
      this.turma_dados = resp_turma.data;
      this.turma_ativa = this.turma_dados[0].ativa;
  },
  async beforeMount(){
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));

    this.theAno = sessionStorage.getItem("anoSelecionado");

    if (this.etapa_id == undefined) {
      this.$router.push({name: 'turmas'});
    }
    try {
      this.loading = true;
      const data = await SegmentoEscolar.obtemUm(this.etapa_id);
      const segmentInfo = data.data;
      this.segmentoTipo = segmentInfo.tipo;
      const data1 = await SeriesEscolar.obtemUm(this.periodo_id);
      this.periodoInfo = data1.data;

      //Do tipo educacao infantil
      if (segmentInfo.tipo == 0) {
        await this.verificarDisciplinaEducacoInfantil();
        this.segmentInfoTipo = 0;
      }
      //Do tipo fundamental 1 ou ERN
      else if (segmentInfo.tipo == 1) {
        this.segmentInfoTipo = 1;
      }
      //Do tipo fundamental 2
      else if (segmentInfo.tipo == 2) {
        this.segmentInfoTipo = 2;
      }
      //Do tipo EJA
      else if (segmentInfo.tipo == 3) {
        //Verificar de qual tipo é o EJA
        //Se for do tipo 1, a turma terá apenas um professor
        if (this.periodoInfo.tipo == 1) {
          this.segmentInfoTipo = 1;
        }
        else {
          this.segmentInfoTipo = 2;
        }
      }
      await this.buscaTurmaInfo();
      this.loading = false;

    }
    catch(e) {

    }
  }
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
<style >
.cabecario-turma{
  background-color:#9ec403;
  color: #fff;
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  text-transform: uppercase;
}
.turmaAtiva{
  background-color:#3d9209;
  color: #fff;
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  text-transform: uppercase;
}
.table > :not(:first-child) {
    border-top: 0px solid currentColor;
}
tr{
  border-color: #fff;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
  #botaoSelecionarTudoDisabled .p-picklist-transfer-buttons .p-button:nth-child(2){
    display : none;
  }
  #botaoSelecionarTudoDisabled .p-picklist-transfer-buttons .p-button:nth-child(4){
    display : none;
  }
  #botaoSelecionarTudoDisabled .p-picklist-source-controls{
    display:none;
  }
  #botaoSelecionarTudoDisabled .p-picklist-target-controls{
    display:none;
  }
</style>

<style scoped>
  .pm-card-disabled {
    pointer-events: none;
    opacity: 0.5; /* Adjust the opacity to your preference */
    /* Other styles to visually indicate disabled state */
  }
</style>

